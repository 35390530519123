<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-7 mb-5">
                    <h1 class="text-responsive">
                        Fixed monthly payment option
                    </h1>
                    <h5 class="text-responsive fw-light">
                        For Balance Transfers and Cash Outs, you have the flexibility and option of predictable monthly payments using a fixed monthly charge.
                    </h5>
                </div>
                <div class="col-12 col-md-5">
                    <img
                        class="img-fluid"
                        src="../../assets/images/pages/marketing/landing/FixedMonthlyPayments@2x.jpg"
                        alt="Fixed Monthly Payments"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'FixedPaymentsSection',
        components: { SectionBlock },
    }
</script>
