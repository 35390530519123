<template>
    <section-block>
        <div class="container">
            <div class="row justify-content-md-center text-md-center">
                <div class="col-12">
                    <h1 class="text-responsive">
                        What homeowners <br class="lg xl">
                        say about us.
                    </h1>
                    <div
                        v-if="theme === 'trustpilot'"
                        class="mt-5 mt-md-7 mb-3 text-center"
                    >
                        <img
                            src="@/assets/images/global/trustpilot-5-star.svg"
                            alt="TrustPilot"
                            width="300"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="reviews container-lg">
            <div
                v-for="(review, index) in reviews"
                :key="index"
            >
                <review
                    :name="review.name"
                    :state="review.state"
                    :rating="review.rating"
                    :content="review.content"
                    :theme="theme"
                />
            </div>
        </div>
    </section-block>
</template>

<script>
    import 'tiny-slider/src/tiny-slider.scss'
    import Review from '@/components/Review'
    import { reviews } from '@/utils/reviews'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'ReviewsSection',
        components: { SectionBlock, Review },
        props: {
            theme: {
                type: String,
                required: false,
                default: '',
            },
        },
        computed: {
            reviews: function () {
                return reviews.slice(0, 3)
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/review';
    @import '../../styles/components/reviewsSection';
</style>
