<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Aven vs HELOC
                    </section-header>
                    <h3 class="text-responsive mb-2 mt-2">
                        A Better HELOC
                    </h3>
                </div>
                <div class="col-md">
                    <div class="row text-center">
                        <ul class="mt-4">
                            <li class="g-0 row">
                                <div class="col-auto pe-2">
                                    <img
                                        class="list-item-icon"
                                        src="@/assets/images/global/cash-payment-bill.svg"
                                        alt="Cash Back"
                                    >
                                </div>
                                <div class="col text-start">
                                    <p class="fw-bold mb-0 mt-0">
                                        The only HELOC with 1.5% Unlimited Cashback <sup>5</sup>
                                    </p>
                                    <div class="text-muted">
                                        Earn unlimited 1.5% cashback on every purchase with your Aven card.
                                    </div>
                                </div>
                            </li>
                            <li class="g-0 row mt-5">
                                <div class="col-auto pe-2">
                                    <img
                                        class="list-item-icon"
                                        src="@/assets/images/global/check-payment-give.svg"
                                        alt="Zero to apply"
                                        width="22"
                                    >
                                </div>
                                <div class="col text-start">
                                    <p class="fw-bold mb-0 mt-0">
                                        $0 to apply and get your card <sup>1</sup>
                                    </p>
                                    <div class="text-muted">
                                        Make purchases with a card that is $0 to get – no title costs, no closing costs, no origination fees, and no application fees.
                                    </div>
                                </div>
                            </li>
                            <li class="g-0 row mt-5">
                                <div class="col-auto pe-2">
                                    <img
                                        class="list-item-icon"
                                        src="@/assets/images/global/icon-clock.svg"
                                        alt="Super fast"
                                    >
                                </div>
                                <div class="col text-start">
                                    <p class="fw-bold mb-0 mt-0">
                                        As fast as 15 minutes *
                                    </p>
                                    <div class="text-muted">
                                        The average HELOC can take 4-6 weeks to unlock – not with Aven. Online & Painless.
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    import SectionHeader from '../SectionHeader'
    export default {
        name: 'BetterHelocSection',
        components: { SectionHeader, SectionBlock },
    }
</script>

<style lang="scss" scoped></style>
