<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-responsive">
                        It’s a HELOC...<br>
                        It’s a credit card...<br>
                        It’s Aven.
                    </h3>
                    <h5 class="fw-light text-responsive mt-2 mt-md-5">
                        Home equity has low rates but is complex and can take weeks to close. Credit cards are simple and have great rewards but have high interest rates. We combined the best of both
                        to build the only home equity backed credit card.
                    </h5>
                </div>
            </div>
            <div class="row mt-1 mt-md-3 gy-5">
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/home-equity-rates-icon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Home Equity Rates"
                    >
                    <p>
                        <strong>Home Equity Rates</strong><br>
                        Get the low rates of a homeowner with equity, including the ability to Cash Out. Plus, it’s done really, really fast, all online.
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/visa-icon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Credit Card Simplicity & Rewards"
                    >
                    <p>
                        <strong>Credit Card Simplicity & Rewards</strong><br>
                        Get the power of a Visa credit card, a simple app, and cashback rewards. Plus, the ease of paying it back like a credit card.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'WhatIsItSection',
        components: { SectionBlock },
    }
</script>
