<template>
    <section-block>
        <div class="container">
            <div class="row row align-items-center">
                <div class="col">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Features
                    </section-header>
                    <h3 class="text-responsive mb-2 mt-2">
                        As Featured On
                    </h3>
                    <h5 class="text-responsive text-responsive fw-light">
                        We work with great partners. Places where you might have seen us:
                    </h5>
                </div>
                <div class="col-md">
                    <div class="row text-center">
                        <div class="col-4 col-md-12 mt-md-5">
                            <img
                                src="@/assets/images/global/trustpilot_logo.png"
                                alt="Trust Pilot"
                                class="partner-logo"
                            >
                        </div>
                        <div class="col-4 col-md-12 mt-md-5">
                            <img
                                src="@/assets/images/global/bankrate_logo.png"
                                alt="BankRate"
                                class="partner-logo"
                            >
                        </div>
                        <div class="col-4 col-md-12 mt-md-5">
                            <img
                                src="@/assets/images/global/lendingtree_logo.png"
                                alt="Lending Tree"
                                class="partner-logo"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    import SectionHeader from '../SectionHeader'
    export default {
        name: 'FeaturedSection',
        components: { SectionHeader, SectionBlock },
    }
</script>

<style lang="scss" scoped>
    .partner-logo {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 126px;
        }
    }
</style>
