<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="text-responsive text-center">
                        See how homeowners <br class="md lg xl">
                        save money
                    </h1>
                </div>
            </div>
            <div class="row mt-3 mt-md-6">
                <div class="stat container-lg">
                    <div class="card rounded-3 p-2 p-md-3">
                        <div>
                            <img
                                src="@/assets/images/global/homedepot.png"
                                alt="Home Depot"
                                height="64"
                                width="64"
                            >
                        </div>
                        <h5 class="mt-2">
                            Home Improvement
                        </h5>
                        <p class="mb-0">
                            An Aven customer gained $165 in cashback at Home Depot with a $11,000 purchase.
                        </p>
                    </div>

                    <div class="card rounded-3 p-2 p-md-3">
                        <div>
                            <img
                                src="@/assets/images/global/balancetransfer.png"
                                alt="Balance Transfer"
                                height="64"
                                width="64"
                            >
                        </div>
                        <h5 class="mt-2">
                            Balance Transfer
                        </h5>
                        <p class="mb-0">
                            Recently a family is saving $3,777 a year on their credit card bill by balance transferring to Aven.<sup><a href="#footnote_5">6</a></sup>
                        </p>
                    </div>

                    <div class="card rounded-3 p-2 p-md-3">
                        <div>
                            <img
                                src="@/assets/images/global/airbnb.png"
                                alt="AirBnb"
                                height="64"
                                width="64"
                            >
                        </div>
                        <h5 class="mt-2">
                            Booking a Trip
                        </h5>
                        <p class="mb-0">
                            A recent Aven traveler spent $2,000 on a wonderful AirBnB vacation.
                        </p>
                    </div>

                    <div class="card rounded-3 p-2 p-md-3">
                        <div>
                            <img
                                src="@/assets/images/global/unlimitedcashback.png"
                                alt="Unlimited Cashback"
                                height="64"
                                width="64"
                            >
                        </div>
                        <h5 class="mt-2">
                            Unlimited Cashback
                        </h5>
                        <p class="mb-0">
                            An Aven customer received $176 in cashback recently from an $11,700 purchase.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-8 offset-md-2">
                    <p class="text-muted text-md-center small">
                        Aven has no partnership or business engagement with these merchants. Merchant names and logos are provided to illustrate where existing customers are using their Aven Card.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'CommunityStatsSection',
        components: { SectionBlock },
    }
</script>

<style lang="scss">
    @import '../../styles/components/communityStats';
</style>
